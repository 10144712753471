// WorkerManagement.js
import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Modal from "react-modal";
import moment from "moment/moment";

// Example UI components (adjust these if you have your own)
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";

import BASE_API_URL from "../config";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
  },
};

const convertExcelDate = (serial) => {
  if (typeof serial === "number") {
    const excelEpoch = new Date(1900, 0, serial - 1);
    return excelEpoch.toISOString().split("T")[0];
  }
  if (typeof serial === "string") {
    return moment(serial).format("MM-DD-YYYY");
  }
  return serial;
};

const WorkerManagement = () => {
  // Worker state now includes termination_date and editHistory (for edit mode)
  const [worker, setWorker] = useState({
    employee_number: "",
    name: "",
    start_date: "",
    termination_date: "",
    supervisor: "",
    position: "",
    roster: "",
    section: "",
    // Optionally: include editedBy, changes, etc.
    // editedBy: "",
    // changes: {}
  });
  const [workers, setWorkers] = useState([]); // Main data store
  const [rowData, setRowData] = useState([]); // Data for the grid
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [rosterList, setRosterList] = useState([]);
  const authToken = localStorage.getItem("authToken");

  // File upload ref
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchWorkers();
    fetchRosterList();
  }, []);

  const fetchRosterList = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/rosters/list`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setRosterList(response.data);
    } catch (error) {
      console.error("Error fetching rosters:", error);
    }
  };

  const fetchWorkers = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/workers/list`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      console.log("Response data:", response.data);
      const sanitizedData = response.data.workers.map((worker) => ({
        id: worker._id,
        employee_number: worker.employee_number,
        name: worker.name,
        start_date: convertExcelDate(worker["Start Date"] || worker.start_date),
        termination_date: worker.termination_date
          ? convertExcelDate(worker.termination_date)
          : null,
        supervisor: worker.supervisor,
        position: worker.position,
        roster: worker.roster_type || worker.roster,
        section: worker.section,
        editHistory: worker.editHistory || [],
      }));
    
      setWorkers(sanitizedData);
      setRowData(sanitizedData);
    } catch (error) {
      console.error("Error fetching workers:", error);
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWorker((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAddWorker = async () => {
    try {
      const response = await axios.post(
        `${BASE_API_URL}/api/workers/add`,
        worker,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      // The backend returns the new worker document
      const newWorker = {
        ...response.data,
        start_date: convertExcelDate(response.data["Start Date"] || response.data.start_date),
        termination_date: response.data.termination_date
          ? convertExcelDate(response.data.termination_date)
          : null,
        editHistory: response.data.editHistory || [],
      };
      setWorkers((prev) => [newWorker, ...prev]);
      setRowData((prev) => [newWorker, ...prev]);
      alert("Worker added successfully");
      closeModal();
    } catch (error) {
      console.error("Error adding worker:", error);
      alert("Error adding worker");
    }
  };

  const handleUpdateWorker = async () => {
    console.log("Update worker clicked", worker);
    try {
      const response = await axios.put(
        `${BASE_API_URL}/api/workers/${worker.id}`,
        worker,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      console.log("Response from update:", response.data);
      const updatedWorker = {
        ...response.data,
        start_date: convertExcelDate(response.data["Start Date"] || response.data.start_date),
        termination_date: response.data.termination_date
          ? convertExcelDate(response.data.termination_date)
          : null,
      };
      setWorkers((prev) =>
        prev.map((w) => (w.id === updatedWorker._id || w.id === updatedWorker.id ? updatedWorker : w))
      );
      setRowData((prev) =>
        prev.map((w) => (w.id === updatedWorker._id || w.id === updatedWorker.id ? updatedWorker : w))
      );
      alert("Worker updated successfully");
      closeModal();
    } catch (error) {
      console.error("Error updating worker:", error);
      alert("Error updating worker");
    }
  };

  const handleDeleteWorker = async (id) => {
    try {
      await axios.delete(`${BASE_API_URL}/api/workers/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setWorkers((prev) => prev.filter((worker) => worker.id !== id));
      setRowData((prev) => prev.filter((worker) => worker.id !== id));
      alert("Worker deleted successfully");
    } catch (error) {
      console.error("Error deleting worker:", error);
      alert("Error deleting worker");
    }
  };

  const filteredRowData = useMemo(() => {
    if (!searchTerm) return rowData;
    return workers.filter((worker) =>
      Object.values(worker).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [rowData, searchTerm, workers]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditWorker = (selectedWorker) => {
    setWorker(selectedWorker);
    setIsEditMode(true);
    setModalIsOpen(true);
  };

  const openModal = () => {
    // Reset form for adding a new worker
    setWorker({
      employee_number: "",
      name: "",
      start_date: "",
      termination_date: "",
      supervisor: "",
      position: "",
      roster: "",
      section: "",
      editHistory: [],
    });
    setIsEditMode(false);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Grid column definitions, including termination date and actions.
  const columnDefs = [
    { headerName: "Employee ID", field: "employee_number", sortable: true, filter: true },
    { headerName: "Employee Name", field: "name", sortable: true, filter: true },
    { headerName: "Start Date", field: "start_date", sortable: true, filter: true },
    { headerName: "Termination Date", field: "termination_date", sortable: true, filter: true },
    { headerName: "Supervisor", field: "supervisor", sortable: true, filter: true },
    { headerName: "Position", field: "position", sortable: true, filter: true },
    { headerName: "Roster", field: "roster", sortable: true, filter: true },
    { headerName: "Section", field: "section", sortable: true, filter: true },
    {
      headerName: "Actions",
      cellRenderer: (params) => (
        <div className="flex space-x-2">
          <Button onClick={() => handleEditWorker(params.data)} className="bg-blue-600 hover:bg-blue-700 text-white" size="sm">
            Edit
          </Button>
          <Button onClick={() => handleDeleteWorker(params.data.id)} className="bg-red-600 hover:bg-red-700 text-white" size="sm">
            Delete
          </Button>
        </div>
      ),
    },
  ];

  // Bulk file upload handler
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(`${BASE_API_URL}/api/workers/bulk-import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("Bulk import response:", response.data);
      alert("Workers imported successfully");
      closeModal();
      fetchWorkers();
    } catch (error) {
      console.error("Error uploading workers:", error);
      alert("Failed to upload workers. Please try again.");
    }
  };

  const openFileDialog = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <Card className="max-w-8xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-gray-800">Blue-Collar Management</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="mb-4">
            <Input
              type="text"
              placeholder="Search workers..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-full"
            />
          </div>
          <div className="flex">
            <div className="mb-4 mr-3">
              <Button onClick={openModal} className="bg-blue-600 hover:bg-blue-700 text-white">
                Add Worker
              </Button>
            </div>
            <div className="mb-4">
              <Button onClick={openFileDialog} className="bg-blue-600 hover:bg-blue-700 text-white">
                Bulk Worker Add
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                className="hidden"
                accept=".csv, .xlsx"
              />
            </div>
          </div>
          <div className="ag-theme-alpine" style={{ height: "900px", width: "100%" }}>
            <AgGridReact
              rowData={filteredRowData}
              columnDefs={columnDefs}
              defaultColDef={{ flex: 1, minWidth: 150 }}
              pagination={true}
              paginationPageSize={20}
              // If termination_date is set, style the row in a light red
              getRowStyle={(params) =>
                params.data.termination_date ? { background: "#f8d7da", color: "#842029" } : null
              }
            />
          </div>
        </CardContent>
      </Card>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customModalStyles}>
        <h2 className="text-lg font-bold mb-4">{isEditMode ? "Edit Worker" : "Add Worker"}</h2>
        <Input
          type="text"
          name="employee_number"
          placeholder="Employee ID"
          value={worker.employee_number}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        <Input
          type="text"
          name="name"
          placeholder="Name"
          value={worker.name}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        <Input
          type="date"
          name="start_date"
          placeholder="Start Date"
          value={worker.start_date}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        {/* Termination Date Field */}
        <Input
          type="date"
          name="termination_date"
          placeholder="Termination Date"
          value={worker.termination_date || ""}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        <Input
          type="text"
          name="supervisor"
          placeholder="Supervisor"
          value={worker.supervisor}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        <Input
          type="text"
          name="position"
          placeholder="Position"
          value={worker.position}
          onChange={handleInputChange}
          className="mb-2 w-full"
        />
        <select
          id="roster"
          name="roster"
          value={worker.roster}
          onChange={handleInputChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
        >
          <option value="">Select Roster</option>
          {rosterList.map((roster, index) => (
            <option key={index} value={roster.roster_type}>
              {roster.roster_type}
            </option>
          ))}
        </select>
        <Input
          type="text"
          name="section"
          placeholder="Section"
          value={worker.section}
          onChange={handleInputChange}
          className="mb-4 w-full mt-2"
        />
        <Button
          onClick={isEditMode ? handleUpdateWorker : handleAddWorker}
          className="bg-blue-600 hover:bg-blue-700 text-white"
        >
          {isEditMode ? "Update Worker" : "Add Worker"}
        </Button>
        <Button onClick={closeModal} className="bg-gray-500 hover:bg-gray-600 text-white mt-2 ml-3">
          Cancel
        </Button>
        {/* Show edit history in edit mode */}
        {isEditMode && (
          <div className="mt-4">
            <h3 className="font-bold mb-2">Edit History</h3>
            {worker.editHistory && worker.editHistory.length > 0 ? (
              <ul className="list-disc pl-5 text-sm">
                {worker.editHistory.map((entry, index) => (
                  <li key={index}>
                    {entry.editedBy} on {moment(entry.editedAt).format("MM-DD-YYYY")}{" "}
                    {entry.changes ? `- changed ${JSON.stringify(entry.changes)}` : ""}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm italic">No edits available.</p>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default WorkerManagement;
