import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import { Users, Plane, Car, Calendar, X, PlaneTakeoff,PlaneLanding } from 'lucide-react';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { motion, AnimatePresence } from 'framer-motion';
import { RosterContext } from '../context/rosterContext'; // Import the RosterContext
import axios from 'axios';
import BASE_API_URL from '../config';
import { useNavigate } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const colors = {
  primary: 'from-purple-600 to-purple-700',
  secondary: 'from-blue-600 to-blue-700',
  accent1: 'from-indigo-600 to-indigo-700',
  accent2: 'from-violet-600 to-violet-700',
  accent3: 'from-fuchsia-600 to-fuchsia-700',
  warning: 'from-amber-500 to-amber-600',
};

const StatCard = ({ title, value, icon: Icon, gradientColors, onClick }) => (
  <div 
    className={`p-6 rounded-xl shadow-lg bg-gradient-to-br ${gradientColors} hover:shadow-xl transition-all duration-300 cursor-pointer`} 
    onClick={onClick}
  >
    <div className="flex items-center justify-between">
      <div>
        <h2 className="text-white/90 text-sm font-medium uppercase tracking-wider">{title}</h2>
        <p className="text-3xl font-bold text-white mt-2">{value}</p>
      </div>
      {Icon && (
        <div className="bg-white/10 backdrop-blur-sm p-3 rounded-lg">
          <Icon className="w-8 h-8 text-white" />
        </div>
      )}
    </div>
  </div>
);

const Modal = ({ title, isOpen, onClose, children }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClose} // Close modal when clicking outside
      >
        <motion.div
          className="bg-white rounded-lg shadow-lg p-4 w-full max-w-lg max-h-[90vh] flex flex-col"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.9 }}
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
        >
          {/* Modal Header */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-gray-800">{title}</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>
          {/* Scrollable Content */}
          <div className="overflow-y-auto flex-1">
            {children}
          </div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);


const Dashboard = () => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const { rosterData } = useContext(RosterContext); // Access rosterData from context
  const [onToday, setOnToday] = useState(0);
  const [drivingIn, setDrivingIn] = useState(0);
  const [drivingOut, setDrivingOut] = useState(0);
  const [flyingIn, setFlyingIn] = useState(0);
  const [flyingOut, setFlyingOut] = useState(0);
  const [onLeave, setOnLeave] = useState(0);
  const [modalData, setModalData] = useState({ isOpen: false, title: '', content: [] });
  const [greeting, setGreeting] = useState('');
  const [dashboardData, setDashBoardData] = useState([]);
  const [workingEmployees, setWorkingEmployees] = useState([]);
  const [leaveEmployees, setLeaveEmployees] = useState([]);
  const [drivingInEmployees, setDrivingInEmployees] = useState([]);
  const [drivingOutEmployees, setDrivingOutEmployees] =useState([]);  
  const [flyingInEmployees, setFlyingInEmployees ] = useState([]); 
  const [flyingOutEmployees, setFlyingOutEmployees ] = useState([]);           


  console.log('modalData', modalData);
  useEffect(() => {
    if (rosterData.length > 0) {
     // calculateStats(rosterData); // Call calculateStats whenever rosterData changes
      setGreetingBasedOnTime();
      fetchDashboardData();
    }
  }, [rosterData]);

  const fetchDashboardData = async() =>{
    try {
      const response = await axios.get(`${BASE_API_URL}/api/dashboard`, {
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
        },
      });
      console.log('fetchDashboardData response', response);
      const dashboardData = response.data.data;
      setDashBoardData(dashboardData);
      setOnToday(dashboardData.working_count);
      setDrivingIn(dashboardData.driving_in_count);
      setDrivingOut(dashboardData.driving_out_count);
      setFlyingIn(dashboardData.flying_in_count);
      setFlyingOut(dashboardData.flying_out_count);
      setOnLeave(dashboardData.leave_count);
    } catch (error) {
     if(error.response.data.message === 'Forbidden: Invalid token'){
      localStorage.clear();
      navigate('/', { replace: true })
     }
      console.error('Error fetching roster data:', error);
    }
  }

  const setGreetingBasedOnTime = () => {
    const hour = dayjs().hour();
    let greeting = 'Hello';
  
    if (hour < 12) greeting = 'Good morning';
    else if (hour < 18) greeting = 'Good afternoon';
    else greeting = 'Good evening';
  
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.firstName) {
        greeting += `, ${user.firstName}`;
      }
    } catch (error) {
      console.error("Error parsing user from localStorage", error);
    }
  
    setGreeting(greeting);
  };
  

  const calculateStats = (workersData) => {
    console.log('workersData',workersData);
    const today = dayjs();
    let onTodayCount = 0;
    let drivingInCount = 0;
    let drivingOutCount = 0;
    let flyingInCount = 0;
    let flyingOutCount = 0;
    let leaveCount = 0;

    const onTodayWorkers = [];
    const drivingInWorkers = [];
    const drivingOutWorkers = [];
    const flyingInWorkers = [];
    const flyingOutWorkers = [];
    const onLeaveWorkers = [];

    workersData.forEach((worker) => {
      const roster = worker.roster_data || [];
      const isOnLeave = worker.LeaveData?.some(
        (leave) => today.isAfter(dayjs(leave.startDate)) && today.isBefore(dayjs(leave.endDate).add(1, 'day'))
      );

      if (isOnLeave) {
        leaveCount++;
        onLeaveWorkers.push(worker.Name);
      }

      const isWorkingToday = roster.some((day) => day.date === today.format('YYYY-MM-DD'));

      if (isWorkingToday) {
        onTodayCount++;
        onTodayWorkers.push(worker.Name);
        const dayOfRoster = roster.find((day) => day.date === today.format('YYYY-MM-DD'));

        if (dayOfRoster.isWorking && dayOfRoster.index === 0) {
          if (worker.roster.startsWith('FIFO')) {
            flyingInCount++;
            flyingInWorkers.push(worker.Name);
          }
          if (worker.roster.startsWith('DIDO')) {
            drivingInCount++;
            drivingInWorkers.push(worker.Name);
          }
        }

        if (dayOfRoster.isWorking && dayOfRoster.index === roster.length - 1) {
          if (worker.roster.startsWith('FIFO')) {
            flyingOutCount++;
            flyingOutWorkers.push(worker.Name);
          }
          if (worker.roster.startsWith('DIDO')) {
            drivingOutCount++;
            drivingOutWorkers.push(worker.Name);
          }
        }
      }
    });

    setOnToday(onTodayCount);
    setDrivingIn(drivingInCount);
    setDrivingOut(drivingOutCount);
    setFlyingIn(flyingInCount);
    setFlyingOut(flyingOutCount);
    setOnLeave(leaveCount);

    // Assign worker names to modals
    setModalData({
      ...modalData,
      dataSets: {
        'On Today': onTodayWorkers,
        'Driving In Today': drivingInWorkers,
        'Driving Out Today': drivingOutWorkers,
        'Flying In Today': flyingInWorkers,
        'Flying Out Today': flyingOutWorkers,
        'On Leave': onLeaveWorkers,
      },
    });
  };

  const pieChartData = {
    labels: ['Driving In', 'Driving Out', 'Flying In', 'Flying Out'],
    datasets: [
      {
        data: [drivingIn, drivingOut, flyingIn, flyingOut],
        backgroundColor: ['rgba(147, 51, 234, 0.8)', 'rgba(79, 70, 229, 0.8)', 'rgba(124, 58, 237, 0.8)', 'rgba(192, 38, 211, 0.8)'],
        borderColor: ['rgba(147, 51, 234, 1)', 'rgba(79, 70, 229, 1)', 'rgba(124, 58, 237, 1)', 'rgba(192, 38, 211, 1)'],
        borderWidth: 2,
      },
    ],
  };

  const barChartData = {
    labels: ['On Leave', 'Working Today'],
    datasets: [
      {
        label: 'Count',
        data: [onLeave, onToday],
        backgroundColor: ['rgba(147, 51, 234, 0.8)', 'rgba(79, 70, 229, 0.8)'],
        borderColor: ['rgba(147, 51, 234, 1)', 'rgba(79, 70, 229, 1)'],
        borderWidth: 2,
      },
    ],
  };

  const handleCardClick = (title) => {
    let content = [];
    if(title === 'On Today'){
      content = dashboardData.working_employees;
    }else if(title === 'Driving In Today'){
      content = dashboardData.driving_in_employees;
    }else if(title === 'Driving Out Today'){
      content = dashboardData.driving_out_employees;
    }else if(title === 'Flying In Today'){
      content = dashboardData.flying_in_employees;
    }else if(title === 'Flying Out Today'){
      content = dashboardData.flying_out_employees;
    }else {
      content = dashboardData.leave_employees;
    }

    console.log('content', content);

    setModalData((prev) => ({
      ...prev,
      isOpen: true,
      title,
      content: content,
    }));
      };

  return (
    <div className="bg-gray-50/50 p-8">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800">{greeting}</h1>
          <p className="text-gray-600 mt-2">This is your roster dashboard.</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          <StatCard title="On Today" value={onToday} icon={Users} gradientColors={colors.primary} onClick={() => handleCardClick('On Today')} />
          <StatCard title="Driving In Today" value={drivingIn} icon={Car} gradientColors={colors.secondary} onClick={() => handleCardClick('Driving In Today')} />
          <StatCard title="Driving Out Today" value={drivingOut} icon={Car} gradientColors={colors.accent1} onClick={() => handleCardClick('Driving Out Today')} />
          <StatCard title="Flying In Today" value={flyingIn} icon={PlaneLanding} gradientColors={colors.accent2} onClick={() => handleCardClick('Flying In Today')} />
          <StatCard title="Flying Out Today" value={flyingOut} icon={PlaneTakeoff} gradientColors={colors.accent3} onClick={() => handleCardClick('Flying Out Today')} />
          <StatCard title="On Leave" value={onLeave} icon={Calendar} gradientColors={colors.warning} onClick={() => handleCardClick('On Leave')} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white/70 backdrop-blur-sm p-6 rounded-xl shadow-lg">
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Transport Today</h2>
            <Pie data={pieChartData} options={{ plugins: { legend: { display: true } } }} />
          </div>

          <div className="bg-white/70 backdrop-blur-sm p-6 rounded-xl shadow-lg">
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Leave Trends</h2>
            <Bar data={barChartData} options={{ plugins: { legend: { display: true } } }} />
          </div>
        </div>
      </div>

      <Modal
  title={modalData.title}
  isOpen={modalData.isOpen}
  onClose={() => setModalData({ ...modalData, isOpen: false })}
>
  <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4">
    <ul className="space-y-2">
      <li className="text-gray-800 font-semibold text-sm border-b border-gray-200 pb-2 flex justify-between">
        <span>Employee number</span>
        <span>Employee name</span>
      </li>
      {modalData.content.map((item, index) => (
        <li
          key={index}
          className="flex justify-between items-center text-gray-700 text-sm px-2 py-2 hover:bg-gray-100 rounded-md"
        >
          <span>{item?.employee_number ? item.employee_number : item.employeeId}</span>
          <span>{item?.name ? item.name : item.employee_name}</span>
        </li>
      ))}
      {modalData.content.length === 0 && (
        <p className="text-gray-500 text-sm text-center mt-2">No data available</p>
      )}
    </ul>
  </div>
</Modal>

    </div>
  );
};

export default Dashboard;