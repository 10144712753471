import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/card";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { motion } from "framer-motion";
import BASE_API_URL from "../config";
import axios from "axios";

// -----------------------
// Custom Cell Editor with Comment Modal
// -----------------------
const RosterCellEditor = forwardRef((props, ref) => {
  // Determine the initial value (if edited previously, props.value might be an object)
  const initialValue =
    typeof props.value === "object" && props.value !== null
      ? props.value.status
      : props.value;
  const [value, setValue] = useState(initialValue || "");
  const [comment, setComment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef(null);
  const currentUser = JSON.parse(
    localStorage.getItem("user") || '{"name": "Unknown"}'
  ).name;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Expose methods to Ag‑Grid.
  useImperativeHandle(ref, () => ({
    getValue() {
      return {
        status: value,
        audit: {
          user: currentUser,
          timestamp: new Date().toLocaleString(),
          comment: comment,
        },
      };
    },
    isCancelAfterEnd() {
      return false;
    },
    // Tell Ag‑Grid to show this editor in a popup so that all UI elements are visible.
    isPopup() {
      return true;
    }
  }));

  // Called when user clicks "Save".
  const handleSave = () => {
    if (comment.trim() === "") {
      // No comment entered: open modal.
      setShowModal(true);
    } else {
      // Comment present: finish editing.
      props.stopEditing();
    }
  };

  // Called when the user confirms their comment in the modal.
  const handleModalConfirm = () => {
    if (comment.trim() !== "") {
      setShowModal(false);
      props.stopEditing();
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "4px" }}>
      {/* Input for status value */}
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        style={{ marginBottom: "4px" }}
      />
      {/* Save button */}
      <button onClick={handleSave}>Save</button>

      {/* Modal overlay to force comment input */}
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "4px",
              minWidth: "300px",
            }}
          >
            <p>Please enter a comment for your change:</p>
            <input
              type="text"
              placeholder="Enter comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              style={{ width: "100%", marginBottom: "10px" }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                onClick={() => setShowModal(false)}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </button>
              <button onClick={handleModalConfirm}>Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

// -----------------------
// Custom Cell Renderer with Weekend Highlighting
// -----------------------
const RosterCellRenderer = (props) => {
  let displayValue = "";
  let audit = null;
  if (props.value && typeof props.value === "object" && props.value.status !== undefined) {
    displayValue = props.value.status;
    audit = props.value.audit;
  } else {
    displayValue = props.value;
  }
  // Use the custom property myDate to get the cell's date.
  const currentDay = props.colDef.myDate ? new Date(props.colDef.myDate) : new Date();
  const isWeekend = [0, 6].includes(currentDay.getDay());

  // Determine colors: default weekday colors; override for weekend or R&R.
  let backgroundColor = "#e6f7ff";
  let textColor = "#005f99";
  if (displayValue === "R&R") {
    backgroundColor = "#f0f0f0";
    textColor = "#888888";
  } else if (isWeekend) {
    backgroundColor = "#ffebcc";
    textColor = "#cc7a00";
  }
  const cellStyle = {
    padding: "4px 8px",
    backgroundColor,
    color: textColor,
    display: "flex",
    alignItems: "center",
  };

  return (
    <span style={cellStyle}>
      {displayValue}
      {audit && (
        <span
          title={`Edited by: ${audit.user}\nAt: ${audit.timestamp}\nComment: ${audit.comment || "No comment"}`}
          style={{ marginLeft: "5px", cursor: "pointer" }}
        >
          &#x1F4DD;
        </span>
      )}
    </span>
  );
};

// -----------------------
// Main RosterPage Component
// -----------------------
const RosterPage = () => {
  const gridRef = useRef();
  const daysToShow = isLeapYear() ? 366 : 365;
  const currentDate = new Date();
  const startDate = currentDate;
  const authToken = localStorage.getItem("authToken");
  const [rosterData, setRosterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startFilterDate, setStartFilterDate] = useState("");
  const [endFilterDate, setEndFilterDate] = useState("");

  const convertExcelDate = (excelDate) => {
    const baseDate = new Date(1900, 0, 1);
    baseDate.setDate(baseDate.getDate() + excelDate - 2);
    return baseDate;
  };

  function isLeapYear() {
    const year = new Date().getFullYear();
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  }

  const formatName = (name) => {
    if (!name) return "N/A";
    const parts = name.split(", ");
    return parts.length === 2 ? `${parts[1]} ${parts[0]}` : name;
  };

  const fetchRosterData = async () => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/api/rosters/list-with-leave`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data) {
        const processedData = response.data.map((employee) => {
          const row = {
            ...employee,
            name: formatName(employee.name),
            start_date: convertExcelDate(employee.start_date).toLocaleDateString(),
          };
          for (let i = 0; i < daysToShow; i++) {
            const currentDay = new Date(startDate);
            currentDay.setDate(startDate.getDate() + i);
            const formattedDate = currentDay.toISOString().split("T")[0];
            const matchingData = employee.roster_data.find(
              (entry) => entry.date === formattedDate
            );
            row[`day${i}`] = matchingData ? String(matchingData.status) : "-";
          }
          return row;
        });
        setRosterData(processedData);
      }
    } catch (error) {
      console.error("Error fetching roster data:", error);
    } finally {
      setLoading(false);
    }
  };

  const onCellValueChanged = (params) => {
    if (params.colDef.field && params.colDef.field.startsWith("day")) {
      const employeeId = params.data.employee_number;
      const updatedValue =
        typeof params.newValue === "object"
          ? params.newValue
          : { status: params.newValue, audit: {} };
      const cellDate = params.colDef.myDate;
      const formattedDate = cellDate.toISOString().split("T")[0];
      const payload = {
        employeeId,
        date: formattedDate,
        newStatus: updatedValue.status,
        audit: updatedValue.audit,
      };
      console.log("Updating cell with payload:", payload);
      axios
        .put(`${BASE_API_URL}/api/rosters/update-cell`, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          console.log("Cell update success:", response.data);
        })
        .catch((error) => {
          console.error("Error updating cell:", error);
        });
    }
  };

  // Build the date columns with custom editor and renderer.
  const dateHeaders = useMemo(() => {
    const headers = [];
    const monthGroups = {};
    const effectiveStartDate = startFilterDate || startDate;
    const effectiveEndDate = endFilterDate;
    const start = new Date(effectiveStartDate);
    const end = effectiveEndDate ? new Date(effectiveEndDate) : null;
    const daysToProcess = end
      ? Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1
      : daysToShow;
    for (let i = 0; i < daysToProcess; i++) {
      const date = new Date(start);
      date.setDate(start.getDate() + i);
      const month = date.toLocaleDateString("en-AU", {
        month: "long",
        year: "numeric",
      });
      if (!monthGroups[month]) {
        monthGroups[month] = [];
      }
      const dayOfWeek = date.toLocaleDateString("en-AU", { weekday: "short" });
      const dayOfMonth = date.getDate();
      monthGroups[month].push({
        headerName: `${dayOfMonth}\n${dayOfWeek}`,
        field: `day${i}`,
        headerTooltip: date.toLocaleDateString("en-AU", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        }),
        width: 120,
        editable: true,
        cellEditorFramework: RosterCellEditor,
        cellRendererFramework: RosterCellRenderer,
        valueFormatter: (params) =>
          params.value != null ? params.value.toString() : "-",
        myDate: date,
      });
    }
    for (const [month, columns] of Object.entries(monthGroups)) {
      headers.push({
        headerName: month,
        children: columns,
      });
    }
    return headers;
  }, [daysToShow, startDate, startFilterDate, endFilterDate]);

  const columnDefs = [
    {
      headerName: "Employee ID",
      field: "employee_number",
      pinned: "left",
      width: 140,
    },
    { headerName: "Employee Name", field: "name", pinned: "left", width: 180 },
    { headerName: "Position", field: "position", pinned: "left", width: 160 },
    { headerName: "Roster", field: "roster_type", pinned: "left", width: 130 },
    { headerName: "Section", field: "section", pinned: "left", width: 150 },
    {
      headerName: "Supervisor",
      field: "supervisor",
      pinned: "left",
      width: 200,
    },
    ...dateHeaders,
  ];

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    fetchRosterData();
  }, []);

  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      .always-show-scrollbars::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
      }
      .always-show-scrollbars::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .3);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
      .always-show-scrollbars {
        overflow: scroll !important;
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  return (
    <div className="h-screen p-4 bg-gray-100 overflow-auto">
      <Card className="h-full w-full rounded-lg shadow-lg overflow-hidden mt-4">
        <CardHeader className="bg-gradient-to-r from-[#2c2c54] to-[#1c1c3a] text-white">
          <CardTitle className="text-2xl">Blue-Collar Roster</CardTitle>
        </CardHeader>
        <CardContent className="p-4">
          <div className="flex space-x-4 mb-4">
            <Input
              type="date"
              placeholder="Start Date"
              value={startFilterDate}
              onChange={(e) => setStartFilterDate(e.target.value)}
              className="w-1/3"
            />
            <Input
              type="date"
              placeholder="End Date"
              value={endFilterDate}
              onChange={(e) => setEndFilterDate(e.target.value)}
              className="w-1/3"
            />
            <Button
              onClick={() => {
                setStartFilterDate("2025-01-01");
                setEndFilterDate("2025-12-31");
              }}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              Reset Filter
            </Button>
          </div>
          <div className="ag-theme-material w-full h-[calc(100vh-280px)] always-show-scrollbars">
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={rosterData}
              defaultColDef={{
                resizable: true,
                sortable: true,
                filter: true,
              }}
              onGridReady={onGridReady}
              onCellValueChanged={onCellValueChanged}
              className="w-full h-full"
              suppressDragLeaveHidesColumns={true}
              alwaysShowHorizontalScroll={true}
              alwaysShowVerticalScroll={true}
              headerHeight={48}
              suppressMovableColumns={false}
              suppressColumnMoveAnimation={true}
              enableCellTextSelection={true}
              ensureDomOrder={true}
              suppressColumnVirtualisation={true}
              rowBuffer={0}
              suppressScrollOnNewData={true}
              maintainColumnOrder={true}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default RosterPage;
